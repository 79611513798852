<form [formGroup]="form" (submit)="trySubmit()">
  <h3>{{ 'Forgot your username?' | translate }}</h3>
  <p>{{ 'You can request your username using your mail address!' | translate }}</p>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Enter your mail address' | translate }}</mat-label>
    <input
      matInput
      type="text"
      autocomplete="email"
      name="mail"
      formControlName="mail"
      required
      />
  </mat-form-field>

  <button mat-stroked-button type="submit" color="primary" [disabled]="!form.valid">
    {{ 'Send mail' | translate }}
  </button>
</form>

@if (serverErrorMsg.length > 0) {
  <p class="errors">
    {{ serverErrorMsg }}
  </p>
}
