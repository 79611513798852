<!-- Actionbars -->
@if (isEntity(element)) {
  <app-actionbar
    [showAnnotateButton]="true"
    [showEditButton]="true"
    [element]="element"
    [showUsesInCollection]="true"
  ></app-actionbar>
}

@if (isCompilation(element)) {
  <app-actionbar
    [showAnnotateButton]="true"
    [showEditButton]="true"
    [element]="element"
  ></app-actionbar>
}
<!-- /Actionbars -->

<div id="detail-content">
  <!-- Viewer -->
  <div class="iframe-container entity-viewer">
    @if (viewerUrl !== '') {
      <iframe
        [src]="viewerUrl | safe: 'resourceUrl'"
        allowfullscreen
      ></iframe>
    }
  </div>
  <!-- /Viewer -->

  <!-- Detail -->
  @if (isEntity(element)) {
    <app-entity-detail [entity]="element"></app-entity-detail>
  }
  @if (isCompilation(element)) {
    <app-compilation-detail
      [compilation]="element"
    ></app-compilation-detail>
  }
  <!-- /Detail -->
</div>
