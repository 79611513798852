<form [formGroup]="form" (submit)="trySubmit()">
  <h3>{{ 'Choose a new password' | translate }}</h3>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Username' | translate }}</mat-label>
    <input
      matInput
      type="text"
      autocomplete="username"
      name="username"
      formControlName="username"
      required
      />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'New password' | translate }}</mat-label>
    <input
      matInput
      type="password"
      autocomplete="new-password"
      name="password"
      formControlName="password"
      required
      />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Repeat new password' | translate }}</mat-label>
    <input
      matInput
      type="password"
      autocomplete="new-password"
      name="password-repeat"
      formControlName="passwordRepeat"
      required
      />
  </mat-form-field>
  <div class="bottom-row">
    <span class="errors">
      @if (form.errors?.not_equal) {
        {{ 'Passwords are not equal' | translate }}
      }
      @if (serverErrorMsg.length > 0) {
        {{ serverErrorMsg }}
      }
    </span>
    <button mat-stroked-button color="accent" type="submit" [disabled]="!form.valid">
      {{ 'Change your password' | translate }}
    </button>
  </div>
</form>
