<app-actionbar
  [showFilters]="true"
  (searchTextChange)="this.searchText = $event; this.searchTextChanged()"
  (mediaTypesChange)="this.mediaTypesSelected = $event; this.updateFilter()"
  (filterTypesChange)="this.filterTypesSelected = $event; this.updateFilter()"
  (showCompilationsChange)="this.showCompilations = $event; this.updateFilter()"
  (sortOrderChange)="this.sortOrder = $event; this.updateFilter()"
></app-actionbar>

<section class="content">
  <div class="entity-grid" id="main">
    @for (element of filteredResults; track element) {
      <app-grid-element
        class="grid-item"
        [element]="element"
        [quickAddToCollectionMenu]="quickAddToCollectionMenu"
        (updateSelectedObject)="selectObjectId = $event"
      ></app-grid-element>
    }
  </div>

  <div class="paginator-container">
    <mat-paginator
      [length]="paginatorLength"
      [pageSize]="paginatorPageSize"
      [pageSizeOptions]="[30]"
      (page)="changePage($event)"
      [pageIndex]="paginatorPageIndex"
      >
    </mat-paginator>
  </div>
</section>

<mat-menu #quickAddToCollectionMenu="matMenu">
  @if (!(isAuthenticated$ | async)) {
    <div style="padding: 0 2rem">
      <p>{{ 'You have to login to create and modify collections.' | translate }}</p>
    </div>
  }
  @if (isAuthenticated$ | async) {
    <button class="quick-add-button" mat-button (click)="openCompilationWizard(selectObjectId)">
      <mat-icon color="primary">create_new_folder</mat-icon>
      <span>{{ 'New collection with this object' | translate }}</span>
    </button>
    @if (userCompilations.length > 0) {
      @for (compilation of userCompilations; track compilation) {
        <button
          class="quick-add-button"
          mat-button
          (click)="quickAddToCompilation(compilation)"
          >
          {{ compilation.name }}
        </button>
      }
    }
  }
</mat-menu>
