@if (institution$ | async; as institution) {
  <h3>{{ institution.name }}</h3>
  @if (address$ | async; as address) {
    <div class="address">
      @if (address.country) {
        <p>{{ address.country }}</p>
      }
      @if (address.postcode || address.city) {
        <p>
          @if (address.postcode) {
            <span>{{ address.postcode }}</span>
          }
          @if (address.city) {
            <span>{{ address.city }}</span>
          }
        </p>
      }
      @if (address.street || address.number) {
        <p>
          @if (address.street) {
            <span>{{ address.street }}</span>
          }
          @if (address.number) {
            <span>{{ address.number }}</span>
          }
        </p>
      }
      @if (address.building) {
        <p>{{ address.building }}</p>
      }
    </div>
  }
  @if (note$ | async; as note) {
    <p>{{ note }}</p>
  }
  @if (roles$ | async; as roles) {
    <mat-chip-listbox>
      @for (role of roles; track role) {
        <mat-chip-option color="primary">{{ role }}</mat-chip-option>
      }
    </mat-chip-listbox>
  }
}
