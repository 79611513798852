<app-actionbar></app-actionbar>

<section class="content">
  <h1>{{ 'Collaborate' | translate }}</h1>

  <div id="groups">
    <h2>{{ 'Groups' | translate }}</h2>
    <div>
      {{ 'Groups are a beautiful way to work together with other Kompakkt-users.' | translate }}
    </div>

    <!-- Groups created by the owner -->
    @if (!showPartakingGroups) {
      <div class="collaborate-grid">
        @for (group of userGroups; track group) {
          <mat-card appearance="outlined" class="entity-grid-card">
            <mat-card-title>{{ group.name }}</mat-card-title>
            <mat-card-subtitle
              >{{ 'Members' | translate }}: {{ group.members.length }} | {{ 'Owners' | translate }}: {{ group.owners.length }}</mat-card-subtitle
              >
              <mat-card-actions>
                <button
                  mat-icon-button
                  matTooltip="{{ 'View group members' | translate }}"
                  (click)="openMemberList(group)"
                  >
                  <mat-icon color="primary">group</mat-icon>
                </button>
                <button
                  mat-icon-button
                  matTooltip="{{ 'Edit group' | translate }}"
                  (click)="openGroupCreation(group)"
                  >
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button
                  mat-icon-button
                  matTooltip="{{ 'Delete group' | translate }}"
                  (click)="removeGroupDialog(group)"
                  >
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </mat-card-actions>
            </mat-card>
          }
        </div>
      }

      <!-- Groups the user is part of as Member or Owner -->
      @if (showPartakingGroups) {
        @if (partakingGroups.length === 0) {
          <div>
            <p>{{ 'You are not partaking in any group' | translate }}</p>
          </div>
        }
        <div class="collaborate-grid">
          @for (group of partakingGroups; track group) {
            <mat-card
              appearance="outlined"
              class="entity-grid-card"
              >
              <mat-card-title>{{ group.name }}</mat-card-title>
              <mat-card-subtitle
                >{{ 'Members' | translate }}: {{ group.members.length }} | {{ 'Owners' | translate }}: {{ group.owners.length }}</mat-card-subtitle
                >
                <mat-card-actions>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'View members' | translate }}"
                    (click)="openMemberList(group)"
                    color="primary"
                    >
                    <mat-icon>group</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="{{ 'Leave group' | translate }}" disabled>
                    <mat-icon>directions_run</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            }
          </div>
        }
        <mat-action-row class="action-row-left-align">
          <div class="tab-help">
            <div class="tab-help-slider-container">
              <mat-chip-listbox>
                <mat-chip-option
                  color="accent"
                  (click)="showPartakingGroups = false"
                  [selected]="!showPartakingGroups"
                  >{{ 'My groups' | translate }}
                </mat-chip-option>
                <mat-slide-toggle
                  [(ngModel)]="showPartakingGroups"
                ></mat-slide-toggle>
                <mat-chip-option
                  color="accent"
                  (click)="showPartakingGroups = true"
                  [selected]="showPartakingGroups"
                  >{{ 'Groups I partake in' | translate }}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <button mat-raised-button color="primary" (click)="openGroupCreation()">
              {{ 'Create a new group' | translate }}
            </button>
          </div>
        </mat-action-row>
      </div>

      <div id="collections">
        <h2>{{ 'Collections' | translate }}</h2>
        <div>
          {{ 'You are giving a course and would like to annotate objects together with your students? You work together with colleagues on a specific topic? Then create a collection or invite other members to your collection!' | translate }}
        </div>

        @if (!showPartakingCompilations) {
          @if (userCompilations.length === 0) {
            <div>
              <p>{{ 'You do not have any collections' | translate }}</p>
            </div>
          }
          <div class="entity-grid" id="main">
            @for (element of userCompilations; track element) {
              <div class="grid-item">
                <app-grid-element
                  [element]="element"
                  [disableTypeInfo]="false"
                  [disableNavigationOnClick]="true"
                  >
                </app-grid-element>
                <!-- Advanced settings -->
                <button
                  class="actionbutton"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  color="primary"
                  >
                  <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu" yPosition="below">
                  <button mat-menu-item (click)="openCompilationCreation(element)">
                    <mat-icon color="primary">edit</mat-icon>
                    {{ 'Edit compilation' | translate }}
                  </button>
                  <button mat-menu-item (click)="removeCompilationDialog(element)">
                    <mat-icon color="warn">delete</mat-icon>
                    {{ 'Delete compilation' | translate }}
                  </button>
                </mat-menu>
              </div>
            }
          </div>
        }

        @if (showPartakingCompilations) {
          @if (partakingCompilations.length === 0) {
            <div>
              <p>{{ 'You are not partaking in any collections' | translate }}</p>
            </div>
          }
          <div class="entity-grid">
            @for (element of partakingCompilations; track element) {
              <app-grid-element
                class="grid-item"
                [element]="element"
                [disableTypeInfo]="false"
                [disableNavigationOnClick]="true"
                >
              </app-grid-element>
            }
          </div>
        }

        <mat-action-row class="action-row-left-align">
          <div class="tab-help">
            <div class="tab-help-slider-container">
              <mat-chip-listbox>
                <mat-chip-option
                  color="accent"
                  (click)="showPartakingCompilations = false"
                  [selected]="!showPartakingCompilations"
                  >{{ 'My collections' | translate }}
                </mat-chip-option>
                <mat-slide-toggle
                  [(ngModel)]="showPartakingCompilations"
                ></mat-slide-toggle>
                <mat-chip-option
                  color="accent"
                  (click)="showPartakingCompilations = true"
                  [selected]="showPartakingCompilations"
                  >{{ 'Collections I partake in' | translate }}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <button
              mat-raised-button
              color="primary"
              (click)="openCompilationCreation()"
              >
              {{ 'Create a new collection' | translate }}
            </button>
          </div>
        </mat-action-row>
      </div>
    </section>
