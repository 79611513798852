<div id="teaser-container">
  <div id="particles"></div>
  <div id="logo" [class.shown]="viewerLoaded$ | async">
    <iframe
      #viewerFrame
      [src]="viewerUrl | safe: 'resourceUrl'"
      class="display-fix"
      allowtransparency="true"
      allowfullscreen
      (load)="onViewerLoad($event)"
    ></iframe>
    <div>
      <img src="assets/kompakkt-logo-text.svg" alt="Kompakkt" />
      <p>{{ '’cause the world is multidimensional.' | translate }}</p>
    </div>
  </div>
  <div id="cards" #teaserCards>
    <div class="teaser-card" routerLink="/explore">
      <div class="teaser-head">
        <mat-icon>explore</mat-icon>
        <h1>{{ 'Explore, share &amp; embed' | translate }}</h1>
      </div>
      <p>
        {{ 'Kompakkt covers images, videos, audio files and 3D models. Explore them in 3D and become part of our community to share your own content. It is all here, in your browser. More good news: you can embed all uploaded objects on other websites via iframe, said the developers.' | translate }}
      </p>
    </div>
    <div class="teaser-card" routerLink="/annotate">
      <div class="teaser-head">
        <mat-icon>place</mat-icon>
        <h1>{{ 'Annotate' | translate }}</h1>
      </div>
      <p>
        {{ 'Interact with the Kompakkt objects by adding descriptions or references to specific points in space. You can also interlink Kompakkt multimedia objects. This is annotating with Kompakkt. Explore annotations of others, annotate yourself or together with other.' | translate }}
      </p>
    </div>
    <div class="teaser-card" routerLink="/collaborate">
      <div class="teaser-head">
        <mat-icon>people</mat-icon>
        <h1>{{ 'Collaborate' | translate }}</h1>
      </div>
      <p>
        {{ 'Objects, Annotations and the process of annotating allows collaboration with other Kompakkt users. Known from the whole Kompakkt community and family, life is mostly better with friends. Trusting them, Kompakkt loves to share, starting by its own source code.' | translate }}
      </p>
    </div>

    <div id="controls">
      <mat-icon (click)="previousCard()">arrow_left</mat-icon>
      <mat-icon [class.active]="selectedCard % 3 === 0" (click)="setTeaserCard(0)"
        >explore</mat-icon
        >
        <mat-icon [class.active]="selectedCard % 3 === 1" (click)="setTeaserCard(1)">place</mat-icon>
        <mat-icon [class.active]="selectedCard % 3 === 2" (click)="setTeaserCard(2)">people</mat-icon>
        <mat-icon (click)="nextCard()">arrow_right</mat-icon>
      </div>
    </div>
  </div>

  <!-- <app-actionbar></app-actionbar> -->

  <section class="content" id="kompakkt-previews">
    <div class="entity-grid">
      @for (element of teaserEntities; track element) {
        <app-grid-element
          class="grid-item"
          [element]="element"
        ></app-grid-element>
      }
    </div>
  </section>
