<form [formGroup]="form" (submit)="trySubmit()">
  <h3>{{ 'Forgot your password?' | translate }}</h3>
  <p>{{ 'You can request your password using your username and will receive a mail!' | translate }}</p>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Enter your username' | translate }}</mat-label>
    <input
      matInput
      type="text"
      autocomplete="username"
      name="username"
      formControlName="username"
      required
      />
  </mat-form-field>

  <button mat-stroked-button type="submit" color="primary" [disabled]="!form.valid">
    {{ 'Submit password request' | translate }}
  </button>
</form>

@if (serverErrorMsg.length > 0) {
  <p class="errors">
    {{ serverErrorMsg }}
  </p>
}
