@if (person$ | async; as person) {
  <h3>{{ person.prename }} {{ person.name }}</h3>
  @if (contact$ | async; as contact) {
    @if (contact.mail) {
      <p>{{ contact.mail }}</p>
    }
    @if (contact.note) {
      <p>{{ contact.note }}</p>
    }
    @if (contact.phonenumber) {
      <p>{{ contact.phonenumber }}</p>
    }
  }
  @if (roles$ | async; as roles) {
    <mat-chip-listbox>
      @for (role of roles; track role) {
        <mat-chip-option color="primary">{{ role }}</mat-chip-option>
      }
    </mat-chip-listbox>
  }
  @if (institutions$ | async; as institutions) {
    <div class="block">
      @for (institution of institutions; track institution) {
        <app-detail-institution
          [institution]="institution"
        ></app-detail-institution>
      }
    </div>
  }
}
