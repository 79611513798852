<mat-form-field>
  <mat-label>{{ 'Language' | translate }}</mat-label>
  <mat-select [formControl]="selectedLanguage">
    @for (item of languages | keyvalue; track item) {
      <mat-option [value]="item.key">
        {{ item.value }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
