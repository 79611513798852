@if (!preview && !browserSupport.isWebkitDirSupported) {
  <div id="compatibility-warning">
    <h3>{{ 'Note: your browser does not support uploading directories' | translate }}</h3>
    <h3>{{ 'Kompakkt (and the content you want to upload) works best on the latest web technologies' | translate }}</h3>
    <h3>{{ 'Please consider switching to a different browser!' | translate }}</h3>
  </div>
}

@if (!preview) {
  <div id="fileFormats">
    <div class="file-format-line" [class.disabled]="(isDetermined$ | async) && !(isModel$ | async)">
      <div>
        <mat-icon>language</mat-icon>
        <span>{{ '3D models' | translate }}</span>
      </div>
      <span>
        <b
          matTooltip="{{ 'Recommended as this format can fit meshes, textures and materials inside of a single file.' | translate }} {{ 'Best compression' | translate }}"
          >.glb</b
          >,
          <b
            matTooltip="{{ 'Recommended as this format can fit meshes, textures and materials inside of a single file' | translate }}"
            >.babylon</b
            >,
            <i
              matTooltip="{{ 'For best results consider converting your model to one of the recommended formats' | translate }}"
              >.gltf, .obj, .stl</i
              >
            </span>
          </div>
          <div class="file-format-line" [class.disabled]="(isDetermined$ | async) && !(isImage$ | async)">
            <div>
              <mat-icon>image</mat-icon>
              <span>{{ 'Images' | translate }}</span>
            </div>
            <span> <b matTooltip="{{ 'Recommended' | translate }}"> .jpg, .png </b>, .tga, .gif, .bmp </span>
          </div>
          <div class="file-format-line" [class.disabled]="(isDetermined$ | async) && !(isAudio$ | async)">
            <div>
              <mat-icon>audiotrack</mat-icon>
              <span>{{ 'Audio' | translate }}</span>
            </div>
            <span>
              <b matTooltip="{{ 'Recommended and supported by most browsers' | translate }}"> .mp3, .wav </b>,
              <i
                matTooltip="{{ 'Strongly dependent on the browser. For best results consider converting your model to one of the recommended formats' | translate }}"
                >
                .ogg, .m4a
              </i>
            </span>
          </div>
          <div class="file-format-line" [class.disabled]="(isDetermined$ | async) && !(isVideo$ | async)">
            <div>
              <mat-icon>movie</mat-icon>
              <span>{{ 'Video' | translate }}</span>
            </div>
            <span>
              <b matTooltip="{{ 'Recommended and supported by most browsers' | translate }}">.mp4</b>,
              <i
                matTooltip="{{ 'Strongly dependent on the browser. For best results consider converting your model to one of the recommended formats' | translate }}"
                >
                .webm, .ogv
              </i>
            </span>
          </div>
        </div>
      }

      @if (!preview) {
        <div id="uploadButton" [class.disabled]="disableFileInput$ | async">
          @if (browserSupport.isWebkitDirSupported) {
            <div>
              <h3>{{ 'Upload a folder' | translate }}</h3>
              <p>
                {{ 'If you want to upload a 3D model with additional files (e.g. textures or materials), please
                select the folder containing all necessary files' | translate }}
              </p>
              <p>
                {{ 'If you want to make your content available in multiple quality levels, you can store them all
                in the same folder and upload them here' | translate }}
              </p>
              <button mat-raised-button (click)="fileInput.click()">{{ 'Choose a folder' | translate }}</button>
              <input
                hidden
                #fileInput
                type="file"
                webkitdirectory
                multiple
                (change)="handleFileInput(fileInput)"
                />
            </div>
          }
          <div>
            <h3>{{ 'Upload single or multiple files' | translate }}</h3>
            <p>
              {{ 'Images, audio files, video files or any single file 3D models (e.g. GLB or BABYLON files) are
              best uploaded using only the single file' | translate }}
            </p>
            <p>
              {{ 'If your content is available in several qualities or is spread over several files, please use
              the folder option instead' | translate }}
            </p>
            <button mat-raised-button (click)="fileInput.click()">{{ 'Choose files' | translate }}</button>
            <input hidden #fileInput type="file" multiple (change)="handleFileInput(fileInput)" />
          </div>
        </div>
      }

      @if (preview) {
        <div id="mediaTypePreview">
          @if (mediaType$ | async; as mediaType) {
            <h4>{{ 'Mediatype:' | translate }} {{ mediaTypeTexts[mediaType] }}</h4>
            <mat-icon>{{ mediaTypeIcons[mediaType] }}</mat-icon>
          }
        </div>
      }

      @if (queue$ | async; as queue) {
        <h3>{{ 'Queued files' | translate }}</h3>
        @if (queue.length !== 0) {
          <table
            mat-table
            id="uploadTable"
            [dataSource]="queue"
            >
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef>{{ 'Size' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ (element.size / 1024 / 1024).toFixed(2) }}MB</td>
            </ng-container>
            <ng-container matColumnDef="progress">
              <th mat-header-cell *matHeaderCellDef>{{ 'Progress' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.progress }}%</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        } @else {
          <p>{{ 'No items in queue' | translate }}</p>
        }
      }
