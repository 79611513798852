@if (element(); as element) {
  <!-- Default behaviour: Navigate to element when clicked -->
  @if (!disableNavigationOnClick()) {
    @if (element | isEntity) {
      <anim-img
        class="image cursor-pointer"
        [routerLink]="['/entity', element._id]"
        matTooltip="{{ 'Click to view object' | translate }}"
        [matTooltipShowDelay]="1000"
        [src]="imageSource"
        [style.--bg-color]="backgroundColor"
        [alt]="element.name"
      ></anim-img>
    } @else {
      <div
        class="images cursor-pointer"
        [style.--bg-color]="backgroundColor"
        [class.single-image]="imageSources.length == 1"
        [routerLink]="['/compilation', element._id]"
        matTooltip="{{ 'Click to view collection' | translate }}"
        [matTooltipShowDelay]="1000"
      >
        @for (source of imageSources; track source) {
          <anim-img [alt]="element.name" [src]="source"></anim-img>
        }
      </div>
    }
  } @else {
    @if (element | isEntity) {
      <anim-img
        class="image"
        [style.--bg-color]="backgroundColor"
        [src]="imageSource"
        alt="{{ element.name }}"
      ></anim-img>
    } @else {
      <div
        class="images"
        [style.--bg-color]="backgroundColor"
        [class.single-image]="imageSources.length == 1"
      >
        @for (source of imageSources; track source) {
          <anim-img alt="{{ element.name }}" [src]="source"></anim-img>
        }
      </div>
    }
  }
  @if (!disableTypeInfo()) {
    <div class="entity-type">
      <mat-icon
        (click)="openExploreDialog(element)"
        class="button cursor-pointer"
        [matTooltip]="((element | isEntity) ? 'Preview object' : 'Preview collection') | translate"
        >open_in_browser</mat-icon
      >
      @if (tooltipContent.length > 20) {
        <mat-icon class="help cursor-default" [matTooltip]="tooltipContent">description</mat-icon>
      }
      @if (element | isCompilation) {
        @if (isRecentlyAnnotated) {
          <mat-icon
            class="help cursor-default"
            matTooltip="{{ 'This collection has recently been annotated' | translate }}"
            >access_time</mat-icon
          >
        }
        @if (isPasswordProtected) {
          <mat-icon class="help cursor-default" matTooltip="{{ 'Password protected' | translate }}"
            >lock</mat-icon
          >
        }
        <mat-icon class="cursor-default" [matTooltip]="collectionQuantityText">{{
          collectionQuantityIcon
        }}</mat-icon>
        <mat-icon class="help cursor-default" matTooltip="{{ 'Collection' | translate }}">{{
          icons.collection
        }}</mat-icon>
      }
      @if (element | isEntity) {
        @if (quickAddToCollectionMenu(); as menu) {
          <mat-icon
            class="button cursor-pointer"
            matTooltip="{{ 'Quick add to collection' | translate }}"
            [matMenuTriggerFor]="menu"
            (click)="selectObject(element._id)"
            >playlist_add</mat-icon
          >
        }
        <mat-icon class="help cursor-default" [matTooltip]="mtype[mediaType]">{{
          icons[mediaType]
        }}</mat-icon>
      }
    </div>
  }
  <div class="title">
    <div>
      {{ element.name }}
    </div>
  </div>
  <!-- Optional behaviour: Ignore clicks on element (e.g. on profile page) -->
}
