<h1 mat-dialog-title class="center-text-horizontal">{{ 'Password protected' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ 'Enter the password to unlock content' | translate }}</p>
</div>
<div mat-dialog-content>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Password' | translate }}</mat-label>
    <input
      id="password"
      autocomplete="password"
      type="password"
      class="fullwidth"
      matInput
      [(ngModel)]="password"
      [ngModelOptions]="{ standalone: true }"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
    id="btn-cancel"
    mat-button
    type="button"
    color="warn"
    (click)="dialogRef.close(undefined)"
  >
    {{ 'Cancel' | translate }}
  </button>
  <button
    id="btn-login"
    mat-button
    type="submit"
    (click)="dialogRef.close(password)"
    color="primary"
    [disabled]="password.length === 0"
  >
    {{ 'Login' | translate }}
  </button>
</div>
