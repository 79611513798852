<div id="confirmation-container">
  <div mat-dialog-content>
    @for (content of splitMessage; track content) {
      <p>{{ content }}</p>
    }
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onNoClick()">{{ 'No' | translate }}</button>
    <button mat-raised-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>{{ 'Yes' | translate }}</button>
  </div>
</div>
