<form [formGroup]="form" (submit)="trySubmit()" id="authForm">
  <h3>{{ concern !== '' ? concern : ('Login' | translate) }}</h3>
  <mat-form-field class="fullwidth">
    <mat-label matRippleDisabled>{{ 'Enter your username' | translate }}</mat-label>
    <input
     
      matInput
      type="text"
      autocomplete="username"
      name="username"
      formControlName="username"
      required
      />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Enter your password' | translate }}</mat-label>
    <input
      matInput
      type="password"
      autocomplete="current-password"
      name="password"
      formControlName="password"
      required
      />
  </mat-form-field>
  <div class="button-row">
    <button
      id="btn-cancel"
      mat-stroked-button
      type="button"
      color="accent"
      (click)="dialogRef.close(false)"
      [disabled]="waitingForResponse"
      >
      {{ 'Cancel' | translate }}
    </button>
    <button
      id="btn-login"
      mat-stroked-button
      type="submit"
      color="primary"
      [disabled]="!form.valid || waitingForResponse"
      >
      {{ 'Login' | translate }}
    </button>
  </div>
  <p>
    {{ 'Don’t have an account?' | translate }}
    <a href="javascript:void(0)" (click)="openRegistrationDialog()">{{ 'Register' | translate }}</a>
  </p>
</form>
@if (loginFailed) {
  <div class="errors">{{ 'Failed to login.' | translate }}<br />{{ 'Check username and password.' | translate }}</div>
}

<mat-divider></mat-divider>

<div class="help">
  <h3>{{ 'Need help?' | translate }}</h3>
  <div class="help-row">
    <a href="javascript:void(0)" (click)="openForgotUsernameDialog()">{{ 'Forgot username?' | translate }}</a>
    <a href="javascript:void(0)" (click)="openForgotPasswordDialog()">{{ 'Forgot password?' | translate }}</a>
  </div>
</div>
