<form [formGroup]="form" (submit)="trySubmit()" id="registerForm">
  <h3>{{ 'Register' | translate }}</h3>
  <p>
    {{ 'Note: if you are a member of the University of Cologne you can use your University account to login and do not need to register.' | translate }}
  </p>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'E-Mail address' | translate }}</mat-label>
    <input
      name="mail"
      autocomplete="email"
      type="mail"
      matInput
      formControlName="mail"
      />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Given name' | translate }}</mat-label>
    <input
      name="prename"
      autocomplete="given-name"
      type="text"
      matInput
      formControlName="prename"
      />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Last name' | translate }}</mat-label>
    <input
      name="surname"
      autocomplete="last-name"
      type="text"
      matInput
      formControlName="surname"
      />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Username' | translate }}</mat-label>
    <input
      name="username"
      autocomplete="username"
      type="text"
      matInput
      formControlName="username"
      />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Password' | translate }}</mat-label>
    <input
      name="password"
      autocomplete="new-password"
      type="password"
      matInput
      formControlName="password"
      />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <mat-label>{{ 'Repeat password' | translate }}</mat-label>
    <input
      name="password"
      autocomplete="new-password"
      type="password"
      matInput
      formControlName="passwordRepeat"
      />
  </mat-form-field>

  <div class="button-row">
    <button
      mat-stroked-button
      (click)="dialogRef.close('cancel')"
      color="warn"
      [disabled]="waitingForResponse"
      >
      {{ 'Cancel' | translate }}
    </button>
    <button
      mat-stroked-button
      type="submit"
      color="primary"
      [disabled]="!form.valid || waitingForResponse"
      >
      {{ 'Create new account' | translate }}
    </button>
  </div>
</form>

@if (error) {
  <div class="errors">{{ error }}</div>
}
