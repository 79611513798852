<!--
building: string;
number: string;
street: string;
postcode: string;
city: string;
country: string;
-->

@if (address) {
  <div class="address-form">
    <mat-form-field>
      <mat-label>{{ 'Country' | translate }}</mat-label>
      <input
        matInput
        type="text"
        name="country"
        placeholder="{{ 'Enter a country' | translate }}"
        [(ngModel)]="address.country"
        [required]="required"
        />
    <!--mat-hint>Lorem Ipsum</mat-hint-->
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'Postal Code' | translate }}</mat-label>
    <input
      matInput
      type="text"
      name="postcode"
      placeholder="{{ 'Enter a postal code' | translate }}"
      [(ngModel)]="address.postcode"
      [required]="required"
      />
  <!--mat-hint>Lorem Ipsum</mat-hint-->
</mat-form-field>
<mat-form-field>
  <mat-label>{{ 'City' | translate }}</mat-label>
  <input
    matInput
    type="text"
    name="city"
    placeholder="{{ 'Enter a city' | translate }}"
    [(ngModel)]="address.city"
    [required]="required"
    />
<!--mat-hint>Lorem Ipsum</mat-hint-->
</mat-form-field>
<mat-form-field>
  <mat-label>{{ 'Street' | translate }}</mat-label>
  <input
    matInput
    type="text"
    name="street"
    placeholder="{{ 'Enter a street' | translate }}"
    [(ngModel)]="address.street"
    [required]="required"
    />
<!--mat-hint>Lorem Ipsum</mat-hint-->
</mat-form-field>
<mat-form-field>
  <mat-label>{{ 'Number' | translate }}</mat-label>
  <input
    matInput
    type="text"
    name="number"
    placeholder="{{ 'Enter a number' | translate }}"
    [(ngModel)]="address.number"
    />
<!--mat-hint>Lorem Ipsum</mat-hint-->
</mat-form-field>
<mat-form-field>
  <mat-label>{{ 'Building' | translate }}</mat-label>
  <input
    matInput
    type="text"
    name="building"
    placeholder="{{ 'Enter a building' | translate }}"
    [(ngModel)]="address.building"
    />
<!--mat-hint>Lorem Ipsum</mat-hint-->
</mat-form-field>
</div>
}
